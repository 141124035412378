//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Open Sans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');


// Custom functions & mixins
@import "./core/base/functions";
@import "./core/base/mixins";
@import "./core/components/mixins";
// @import "./core/vendors/plugins/mixins";

// Custom variables
@import "components/variables.custom";
@import "./core/components/variables";

// Bootstrap initializaton
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-utilities";

// 3rd-Party plugins variables
// @import "./core/vendors/plugins/variables";

// Custom layout variables
@import "layout/variables";
